import React, {useState, useEffect} from 'react';
import img1 from '../assets/CardImg/img1.png';
import img2 from '../assets/CardImg/img2.png'; 
import img3 from '../assets/CardImg/img3.png'; 
import img4 from '../assets/CardImg/img4.png'; 
import './Cards.css';

const Cards = () => {

  const [scrollDirection, setScrollDirection] = useState('slideUp');

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current) {
      setScrollDirection('slideUp');
    } else if (currentScrollY < lastScrollY.current) {
      setScrollDirection('slideDown');
    }
    lastScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const lastScrollY = React.useRef(window.scrollY);

  return (
    <div className='cards'>
      <div className='card-container'>
        <div className='card'>
          <img src={img1} className='cardimg' alt='Opportunities'></img>
          <h2 className={`title animated ${scrollDirection} delay-1s`}>Unlock Endless Oppportunities</h2>
          <p className={`description animated ${scrollDirection} delay-2s`}>Never miss an opportunity - your gateway to success starts here </p>
        </div>
        <div className='card'>
          <img src={img2} className='cardimg' alt='Jobs & Internships'></img>
          <h2 className={`title animated ${scrollDirection} delay-1s`}>Internships & Jobs</h2>
          <p className={`description animated ${scrollDirection} delay-2s`}>Elevate your networking game for meaningful connections and endless possibilities</p>
        </div>

        <div className='card'>
        <img src={img3} className='cardimg' alt='Learning'></img>
        <h2 className={`title animated ${scrollDirection} delay-1s`}>Learn by Doing</h2>
        <p className={`description animated ${scrollDirection} delay-2s`}>Brideg the gap between theory & practical; learning by engaging in real world projects, internships and hackathons through skillcrunch.</p>
        </div>
        <div className='card'>
          <img src={img4} className='cardimg' alt='Connect'></img>
          <h2 className={`title animated ${scrollDirection} delay-1s`}>Connect & Conquer</h2>
          <p className={`description animated ${scrollDirection} delay-2s`}>Expand your network with industry professionals & share your favourite opportunities with friends effortlessly, turning your network into a collaborative powerhouse</p>
        </div>
      </div>
    </div>
  );
};

export default Cards;
