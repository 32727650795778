import React from 'react';
import './Products.css';

const Products = () => {

  const handlePageClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="products">
      <h2>Our Products</h2>
      <ul>
        <li onClick={() => handlePageClick('https://play.google.com/store/apps/details?id=com.app.skillcrunch')}>SkillCrunch</li>
        <li onClick={() => handlePageClick('https://play.google.com/store/apps/details?id=com.app.unstag')}>Unstag</li>
        <li onClick={() => handlePageClick(' https://play.google.com/store/apps/details?id=com.androidsellerapp.rechargekaro')}>Recharge Karo</li>
      </ul>
    </div>
  );
};

export default Products;
