import React, { useState, useEffect }  from 'react';
import './Title.css';

const Title = () => {

  const [scrollDirection, setScrollDirection] = useState('slideUp');

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current) {
      setScrollDirection('slideUp');
    } else if (currentScrollY < lastScrollY.current) {
      setScrollDirection('slideDown');
    }
    lastScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const lastScrollY = React.useRef(window.scrollY);

 
    
    const handleDownloadClick = () => {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.app.skillcrunch';
      };

  return (
    <div className='home_title'> 
      <div className="header">
        <h1 className={`heading1 animated ${scrollDirection}`}><span className='skill'>Skill</span><span className="crunch">Crunch</span></h1>
        <h1 className={`heading2 animated ${scrollDirection} delay-1s`}>Every Skill  Matters!</h1>
        <h3 className={`heading3 animated ${scrollDirection} delay-1s`}>SkillCrunch is your go-to platform for learning and skills! Stay up to date on opportunities, programs, events, scholarships and hackathons. Don't forget internships and jobs updates!</h3>
        <button onClick={handleDownloadClick} className="download-button">Download Now <i className="fa-solid fa-angle-right"></i></button>
        <p>Now available on Playstore</p>
      </div>
    </div>
  );
};

export default Title;
