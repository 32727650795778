import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h1 className='about-text'> About</h1>
      <p>Welcome to Unstag! Ever had a secret so juicy you just had to share it, but didn't want anyone to know it was you? We've got you covered! At Unstag, you can post all your campus confessions and secrets completely anonymously.</p>
      <p>Like that time you accidently emailed your professor a meme instead of your assignment? Yeah, we want to hear about it. You can like, comment and share the best(or worst) secrets on other platforms. Join the fun on Unstag and let your innergossip run wild!</p>
    </div>
  );
};

export default About;
