import React, { useState, useEffect } from 'react';
import './Personalization.css';

const Personalization = () => {

  const handlePageClick = (url) => {
    window.location.href = url;
  };


    const [scrollDirection, setScrollDirection] = useState('slideUp');

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current) {
      setScrollDirection('slideUp');
    } else if (currentScrollY < lastScrollY.current) {
      setScrollDirection('slideDown');
    }
    lastScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const lastScrollY = React.useRef(window.scrollY);

  return (
    <div className="personalization">
      <div className='grid-container'>
        <div className='gridcard1'>
        </div>
        <div className='gridcard'>
        <h2 className={`title animated ${scrollDirection} delay-1s`}>No more one-size-fits all</h2>
            <p className={`description animated ${scrollDirection} delay-2s`}>We understand that every student is unique, with SkillCrunch's advanced algorithm, get personalized notifications that align with your interests and goals.</p>
        </div>
      </div>
    </div>
  );
};

export default Personalization;
