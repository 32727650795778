import React, { useState }  from 'react';
import SkillCrunch from '../assets/Logo Name/SkillCrunch.png'
import googlePlayStoreBadge1 from '../assets/Google Play Badge/googlePlayStoreBadge1.svg';
import './Navbar.css';

  
const Navbar = () => {
  
    const [isMobile, setIsMobile] = useState(false);

    const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
    };  

    const handleGooglePlayClick = () => {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.app.skillcrunch';
    };
 

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img className="logoN" src={SkillCrunch} alt='SkillCrunch'></img>
      </div>
      <div className={`nav-links ${isMobile ? 'mobile' : ''}`}>
        <a href="/" activeClassName="active">Home</a>
        <a href="#features" activeClassName="active">Features</a>
        <a href="#contact" activeClassName="active">Contact</a>
        <img src={googlePlayStoreBadge1} onClick={handleGooglePlayClick} alt="Download" className="googlePlay-button" />
        
      </div>
      <div className="menu" onClick={toggleMobileMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      
    </nav>
  );
};


export default Navbar;
