// src/components/LandingPage.js
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './LandingPage.css';
import Title from './Title';
import Cards from './Cards';
import Clubhouse from './Clubhouse';
import Opportunities from './Opportunities';
import Personalization from './Personalization';


const LandingPage = () => {

  return (
    <>
    <div className='landing_page'>
    <Navbar />
    
    <section id='home'>
    <Title />
    </section>
    

      <section className="cards-section" id='features'>
        <Cards />
      </section>

      <section className="opportunities-section">
        <Opportunities/>
      </section>
      
      <section className="clubhouse-section">
        <Clubhouse/>
      </section>

      <section className="personalization-section">
        <Personalization/>
      </section>

      <section className="footer-section" >
        <Footer />
      </section>

    </div>
    </>
  );
};

export default LandingPage;
