import React from 'react';
import './Contact.css';

const Contact = () => {

  const handleSocialClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="contact">
      <h2>Contact Us</h2>
      <p onClick={() => handleSocialClick('https://mail.google.com/mail/?view=cm&fs=1&to=contact@skillcrunch.in')}>contact@skillcrunch.in</p>
    </div>
  );
};

export default Contact;
