// src/components/Footer.js
import React from 'react';
import Legal from './Legal';
import Products from './Products';
import About from './About';
import Contact from './Contact';
import SkillCrunch from '../assets/Logo Name/SkillCrunch.png'
import './Footer.css'; // Make sure to create this CSS file

const Footer = () => {

  const handleSocialClick = (url) => {
    window.location.href = url;
  };

  return (
    <>
    <footer className="footer">
        <div className='Logo-content'>
        <img className="logo" src={SkillCrunch} alt='SkillCrunch'></img>
        </div>
      <div className="footer-content">
        <div className='socials-content'>
        <div className="socials">
          <p>Socials</p>
          <div className="icons">
            <i className="fab fa-instagram"  onClick={() => handleSocialClick('https://www.instagram.com/skillcrunch/')}></i>
            <i className="fa-brands fa-threads" onClick={() => handleSocialClick('https://www.threads.net/@skillcrunch')}></i>
            <i class="fa-brands fa-x-twitter" onClick={() => handleSocialClick(' https://x.com/skillcrunch')}></i>
          </div>
        </div>
        </div>
        
          <section className="about-section">
            <About />
          </section>

          <section className="product-section" id='products'>
            <Products/>
          </section>

          <section className="legal-section">
            <Legal />
          </section>

          <section className="contact-section" id='contact'>
            <Contact />
          </section>

      </div>
 
      <div className="footer-bottom">
        <p>© 2024 SkillCrunch Private Limited. All Rights Reserved.</p>
      </div>
    </footer>
    </>
  );
};

export default Footer;
